import React from "react"
import { Link, graphql } from "gatsby"
import Image from 'gatsby-image'
import Layout from "../components/layout"

import SEO from "../components/seo"
import PictureGallery from '../components/pictureGallery'
import * as BlockContent from '@sanity/block-content-to-react'
// equals import above - const BlockContent = require('@sanity/block-content-to-react')
import styled from "@emotion/styled"
// import { css } from "@emotion/core"
import theme from '../shared/theme'
import scrollTo from 'gatsby-plugin-smoothscroll'
import useDimensions from "react-use-dimensions"


const WINDOW_NOT_0 = 1;
const NO_BURGER_WIDTH = 993;

//this is for using richtext editors based on portable text format like sanity use
const serializers = {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    )
  }
}

export const query = graphql`
{
  sanityStartPage {
    slug {
      current
    }
    header_logo
    header_image {
      _key
      _type
      asset {
        fluid {
          ...GatsbySanityImageFluid
        }
      }
    }
    _rawHeaderText
    self_intro_slogan_left
    _rawSelfIntroText
    hashtag_1_headline
    _rawHashtag1Text
    hashtag_2_headline
    _rawHashtag2Text
    hashtag_3_headline
    _rawHashtag3Text
    footer_block_links_headline
    _rawFooterBlockLinksText
  }
}
`;

const IndexPage = ({ data }) => {
  const [position0, setposition] = React.useState(0);
  const [resizeRef, { width }] = useDimensions();
  const [isBurgerOpen, setIsBurgerOpen] = React.useState(false);
 
  const handleWindowScroll = () => {
    window.onscroll = () => {
      window.pageYOffset !== 0 ? setposition(WINDOW_NOT_0) : setposition(0);
    }
  }

  React.useEffect(() => {
    window.addEventListener("scroll", handleWindowScroll);
    return () => {
      window.removeEventListener("scroll", handleWindowScroll);
    };
  });

  const toggleBurgerMenu = () => {
    setIsBurgerOpen(!isBurgerOpen);
  }

  const handleBurgerMenuScroll = (target) => {
    scrollTo(target);
    setTimeout(toggleBurgerMenu, 1000);
  }

  // für Manipulation des Aussehens - nur bei bounce nach oben
  // React.useLayoutEffect(() => {
  //   console.log('useLayoutEffect triggered', window.innerWidth)
  // });

  return (
    <Layout>
    <StyledBody ref={resizeRef}>
      <SEO title="Nadja Büttner Design Thinking" description='Nadja Büttner Design Thinking sowie Agile Development Coach und Consultant'/>
      <StyledHeader>
        <StyledNavigation role="navigation" position={position0 === 0 ? 'top' : 'inPage'}>
          <h1>{data.sanityStartPage.header_logo === null ? '' : data.sanityStartPage.header_logo}</h1>
          {width === undefined ? 'loading' : width > NO_BURGER_WIDTH ? (
            <StyledNavList>
              <li onClick={() => scrollTo('#about_me')}>About Me</li>
              <li onClick={() => scrollTo('#pictureGallery')}>At Work</li>
              <li onClick={() => scrollTo('#hashtagContainer')}>Portfolio</li>
              <li onClick={() => scrollTo('#footer')}>Contact Me</li>
            </StyledNavList>
          ) : (
            <button onClick={toggleBurgerMenu}>{!isBurgerOpen ? '|||' : 'X'}</button>
          )} 
          {isBurgerOpen && width < NO_BURGER_WIDTH ? 
          (<StyledBurgerMenuOverlay>
            <StyledBurgerMenuItemList>
              <li onClick={() => handleBurgerMenuScroll('#about_me')}>About Me</li>
              <li onClick={() => handleBurgerMenuScroll('#pictureGallery')}>At Work</li>
              <li onClick={() => handleBurgerMenuScroll('#hashtagContainer')}>Portfolio</li>
              <li onClick={() => handleBurgerMenuScroll('#footer')}>Contact Me</li>
            </StyledBurgerMenuItemList>
          </StyledBurgerMenuOverlay>) :
          isBurgerOpen && width > NO_BURGER_WIDTH ?
          setIsBurgerOpen(false) :
          null}
        </StyledNavigation>
        <StyledPictureBlock>
            <StyledHeaderPicture fluid={data.sanityStartPage.header_image.asset.fluid === null ? 'no picture' : data.sanityStartPage.header_image.asset.fluid}></StyledHeaderPicture>
            <section>
              <BlockContent blocks={data.sanityStartPage._rawHeaderText} serializers={serializers} />
              <button onClick={() => scrollTo('#footer')}>Send a message</button>
            </section>
        </StyledPictureBlock>
      </StyledHeader>
      <StyledAboutMe id='about_me'>
        <div id='selfIntroSlogan'>{data.sanityStartPage.self_intro_slogan_left}</div>
        <section>
          <BlockContent blocks={data.sanityStartPage._rawSelfIntroText} serializers={serializers} />
        </section>
        {/* {console.log('rawText is: ', data.sanityStartPage._rawSelfIntroText.map((block => block.children.map(child => child.text).join(''))))} */}
      </StyledAboutMe>
      <div id='pictureGallery'>
      <PictureGallery />
      </div>
      <StyledTeaser>My current & past work:</StyledTeaser>
      <StyledHashtagContainer id='hashtagContainer'>
        <div>
          <h3>{data.sanityStartPage.hashtag_1_headline}</h3>
          <BlockContent blocks={data.sanityStartPage._rawHashtag1Text} serializers={serializers} />
        </div>
        <div>
          <h3>{data.sanityStartPage.hashtag_2_headline}</h3>
          <BlockContent blocks={data.sanityStartPage._rawHashtag2Text} serializers={serializers} />
        </div>
        <div>
          <h3>{data.sanityStartPage.hashtag_3_headline}</h3>
          <BlockContent blocks={data.sanityStartPage._rawHashtag3Text} serializers={serializers} />
        </div>
      </StyledHashtagContainer>
      <StyledFooter id='footer'>
        <div>
          <h4>{data.sanityStartPage.footer_block_links_headline}</h4>
          <BlockContent blocks={data.sanityStartPage._rawFooterBlockLinksText} serializers={serializers} />
        </div>
        <div>
          <h4>Connect on LinkedIn</h4>
          <a href='https://www.linkedin.com/in/nadjabuettner/' target='_blank' rel='noopener noreferrer'><img src='../../LinkedIn.png' alt='connect on linkedin'/></a>
        </div>
        <div>
          <StyledLink to="/impressum/">
            <h4>Impressum & Privacy</h4>
            <p>Made with <img src='../../heart.png' alt='website made with heart'/></p>
          </StyledLink>
        </div>
      </StyledFooter>
    </StyledBody>
    </Layout>
)}

const StyledLink = styled(Link)`
    text-decoration: none;
    color: black;
`;

const StyledBody = styled.div`
  background-color: ${theme.colors.bodyBackground};
  font-family: ${theme.fontFamily};
  margin: 0;

  h1 {
    font-family: ${theme.fontFamilyHeading};
  }
`;

const StyledHeader = styled.header`
  position: relative;
  width: 100%;
`;

const StyledNavigation = styled.nav`
  width: 100%;
  background-Color: ${props => props.position === 'top' ? 'transparent' : 'rgba(220, 220, 220, 0.4)'}; 
  position: fixed;
  z-index: 3;
  display: inline-flex;
  color: ${theme.colors.fontBright};
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;

  h1 {
    margin: 0;
    padding: 1vw 0 1vw 2vw;
  }

  button {
    width: 5vw;
    height: 5vw;
    font-size: 1vw;
    margin: 1vw 1vw 1vw 0;
    background-color: ${theme.colors.buttonRed};
    border-radius: 50%;
    position: absolute;
    right: 0;
    padding: 1vw;
    border: none;
    cursor: pointer;
    outline: none;
    z-index: 3;
    color: ${theme.colors.fontBright};
    transform: rotate(90deg);
  }

  @media all and (max-width: 992px) {
    button {
      font-size: 2vw;
      margin: 1vw 2vw 1vw 0;
    }
  }

  @media all and (max-width: 768px) {
    h1 {
      font-size: ${theme.sizes.h1SmallScreen};
    }

    button {
      width: 10vw;
      height: 10vw;
      margin: 2vw;
      padding: .5vw;
      font-size: ${theme.sizes.fontSmallScreen};
    }
  }
`;

const StyledNavList = styled.ul`
  position: absolute;
  right: 0;
  padding: 1vw 0;
  margin: 0;

  li {
    display: inline-block;
    margin-right: 2vw;
    cursor: pointer;
  }
`;

const StyledBurgerMenuOverlay = styled.div`
  width: 100%;
  height: 100vw;
  background-color: black;
  opacity: .8;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  @media all and (max-width: 992px) {
    height: 50vw;
  }

  @media all and (max-width: 768px) {
    height: 100vw;
  }
`;

const StyledBurgerMenuItemList = styled.ul`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  color: white;
  opacity: 1;
  font-weight: bold;
  
  li {
    text-align: center;
    list-style: none;
    cursor: pointer;
  }

  @media all and (max-width: 992px) {
    li {
      font-size: ${theme.sizes.h1SMediumScreen};
    }
  }

  @media all and (max-width: 768px) {
    li{
      font-size: ${theme.sizes.h1SmallScreen};
    }
  }
`;

const StyledHeaderPicture = styled(Image)`
    width: 100%;
    
    @media all and (min-width: 769px) {
      height: 55vw;
    }

    @media all and (max-width: 768px) {
      height: 80vw;
    }
`;

const StyledPictureBlock = styled.div`
  position: relative;

  section {
    position: absolute;
    top: 30%;
    left: 10%;
    padding-right: 35%;
    color: ${theme.colors.fontBright};
    font-size: ${theme.sizes.fontBannerBigScreen};
  }

  section > button {
    border-radius: .5vw;
    padding: 1vw 2vw;
    font-size: ${theme.sizes.fontBigScreenButton};
    border: none;
    background-color: ${theme.colors.buttonRed};
    overflow: hidden;
    color: ${theme.colors.fontBright};
    cursor: pointer;

    &:hover {
      background-color: ${theme.colors.buttonRedHover};
    }
  }

  section > button:focus {
    outline: none;
  }

  @media all and (max-width: 992px) {
    section {
      
      top: 30%;
      left: 10%;
      padding-right: 35%;
      font-size: ${theme.sizes.fontBannerMediumScreen};

      h1 {
        font-size: ${theme.sizes.h1MediumScreen};
      }

      button {
        font-size: ${theme.sizes.fontMediumScreenButton};
        padding: 1vw 1vw;
      }
    }
  }

  @media all and (max-width: 768px) {
    section {
      top: 55%;
      left: 10%;
      padding-right: 5%;
      font-size: ${theme.sizes.fontSmallScreen};
      line-height: ${theme.sizes.fontLineHeightScreen};

      h1 {
        font-size: ${theme.sizes.h1SmallScreen};
      }

      button {
        font-size: ${theme.sizes.fontSmallScreenButton};
        padding: 1vw 3vw;
        border-radius: 1vw;
      }
    }
  }
`;

const StyledAboutMe = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding: 10vw 10vw 10vw 7vw;

  #selfIntroSlogan {
    flex: 1 calc(100% / 3);
    font-size: 5vw;
    line-height: 5vw;
    font-weight: bold;
    padding-right: 3vw;
  }

  section {
    flex: 1 calc(100% / 3 * 2);
    padding-left: 3vw;
    font-size: ${theme.sizes.fontBigScreen};
    line-height: ${theme.sizes.fontLineHeightBigScreen};
  }

  section p {
    text-align: justify;
  }

  section p:first-of-type {
    margin-top: 0;
  }

  @media all and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 15vw 10vw 15vw 7vw;

    section {
      font-size: ${theme.sizes.fontSmallScreen};
      line-height: ${theme.sizes.fontLineHeightSmallScreen};
    }
    
    #selfIntroSlogan {
      font-size: 7vw;
      line-height: 10vw;
      margin-bottom: 10vw;
      text-align: center;
    }
  }
`;

const StyledTeaser = styled.p`
  margin-top: 15vw;
  margin-bottom: 0;

  text-align: center;
  font-size: 5vw;
  line-height: 5vw;
  font-weight: bold;

  @media all and (max-width: 768px) {
    margin-top: 30vw;
    margin-bottom: 2vw;
  }
`;

const StyledHashtagContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  font-size: ${theme.sizes.fontBigScreen};
    line-height: ${theme.sizes.fontLineHeightBigScreen};
  
  div {
    flex: 1 calc(100% / 3);
    padding: 10vw 8vw;
    text-align: center;
  }

  @media all and (max-width: 992px) {
    div {
      padding: 10vw 5vw;
    }

    div:first-of-type {
      padding-left: 8vw;
    }
    
    div:last-of-type {
      padding-right: 8vw;
    }
  }

  @media all and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    font-size: ${theme.sizes.fontSmallScreen};
    line-height: ${theme.sizes.fontLineHeightSmallScreen};

    div {
      padding: 10vw 15vw;
    }

    div:first-of-type {
      margin-top: 10vw;
      padding: 10vw 15vw;
    }

    div:last-of-type {
      padding: 10vw 15vw;
    }
  }
`;

const StyledFooter = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  font-size: 1vw;

  div {
    flex: 1 calc(100% / 3);
    padding: 5vw 8vw;
    text-align: center;
  }

  div:last-child {
    text-align: right;
  }

  div:first-of-type {
    text-align: left;
    padding: 5vw 6vw 5vw 8vw;
  }

  p > img {
    width: 1vw;
    margin-left: .25vw;
  }

  a > img {
    width: 2vw;
  }

  @media all and (max-width: 992px) {
    font-size: ${theme.sizes.fontMediumScreenFooter};
  }

  @media all and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    font-size: ${theme.sizes.fontSmallScreenFooter};
    line-height: ${theme.sizes.fontLineHeightScreen};

    div {
      padding: 5vw 15vw;
    }

    div:last-child {
      text-align: center;
      margin-bottom: 10vw;
    }
  
    div:first-of-type {
      text-align: center;
      margin-top: 10vw;
    }

    p > img {
      width: 2vw;
      margin-left: .25vw;
    }
  
    a > img {
      width: 5vw;
    }
  
  }
`;

export default IndexPage
