import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import Image from 'gatsby-image'
import SEO from "./seo"
import styled from "@emotion/styled"
import Image from 'gatsby-image'
import Modal from 'react-awesome-modal'
import useDimensions from "react-use-dimensions"

  const PictureGallery = () => {
    const [visible, setVisible] = React.useState(false);
    const [modalIndex, setModalIndex] = React.useState(null);
    const [resizeRef, { width }] = useDimensions();

      const data = useStaticQuery(graphql`
          {
            allSanityPictureGallery {
              edges {
                node {
                  alt_text
                  caption
                  image {
                    asset {
                      fluid {
                        ...GatsbySanityImageFluid
                      }
                    }
                  }
                }
              }
            }
          }
      `)
      
    const toggleModal = (index) => {
        setVisible(!visible);
        setModalIndex(index);
    }

    return (
        <React.Fragment>
          <StyledPictureContainer ref={resizeRef}>{data.allSanityPictureGallery === null ? '' : data.allSanityPictureGallery.edges.map((item, index) => (
            <StyledPicture key={index}>
                <SEO title={item.node.caption} description={item.node.alt_text}/> 
                    <Image 
                        onClick={() => toggleModal(index)}
                        fluid={item.node.image.asset.fluid}
                    />
            </StyledPicture>
          ))}
          </StyledPictureContainer>
          {width > 768 ?
          (<Modal  
            key={modalIndex}
            visible={visible}
            width="50%"
            onClickAway={() => toggleModal(modalIndex)}
          >   
              <Image
                fluid={data.allSanityPictureGallery.edges[modalIndex] === undefined 
                ? 
                {base64:'...', aspectRatio: 1.5, src:'...', sizes:'...', srcSet:'...', srcSetWebp:'...', srcWebp:'...'} 
                : 
                data.allSanityPictureGallery.edges[modalIndex].node.image.asset.fluid}
              />
          </Modal>) : 
          null
          }
        </React.Fragment>
      )}

const StyledPictureContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(100% / 5), 1fr));

    @media all and (max-width: 768px) {
      grid-template-columns: repeat(auto-fill, minmax(calc(100% / 2), 1fr));
    }

`;

const StyledPicture = styled.div`
    margin: .1vw;

    &:hover{
      opacity: .5;
      cursor: pointer;
    }

    @media all and (max-width: 768px) {
      margin: .2vw;

      &:hover{
        opacity: 1;
        cursor: auto;
      }
    }
`;

export default PictureGallery;